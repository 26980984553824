import * as z from 'zod'
import { PasswordSchema, UserNameSchema } from '@api/endpoints'
import { Authentication, AuthenticationRequest, UserRoleEnumSchema } from '@api/endpoints/auth'

export const User = Authentication
export type User = z.infer<typeof User>

export const UserList = User.array()
export type UserList = z.infer<typeof UserList>

export const CreateUserSchema = z.object({
  userName: UserNameSchema,
  password: PasswordSchema,
  password2: PasswordSchema,
  role: UserRoleEnumSchema
})
export type CreateUserSchema = z.infer<typeof CreateUserSchema>

export const UserCreatedResponse = AuthenticationRequest.pick({ userName: true })
export type UserCreatedResponse = z.infer<typeof UserCreatedResponse>
