/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { httpCall, httpReceiveOnlyJson, httpSendOnlyJson } from '@digital-magic/zod-fetch'
import { apiBaseUrlV1 } from '@constants/configuration'
import { UserId } from '@api/endpoints'
import { CreateUserSchema, User, UserList } from './types'

const usersUrl = `${apiBaseUrlV1}/admin/users`
const userUrl = (userId: UserId): string => `${usersUrl}/${userId}`

export const getUsers = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: usersUrl,
    responseSchema: UserList
  })

export const postUserCreate = (data: CreateUserSchema) =>
  httpSendOnlyJson({
    method: 'POST',
    url: usersUrl,
    requestSchema: CreateUserSchema,
    data
  })

export const getUser = (userId: UserId) => {
  return httpReceiveOnlyJson({
    method: 'GET',
    url: userUrl(userId),
    responseSchema: User
  })
}

export const deleteUser = (userId: UserId) =>
  httpCall({
    method: 'DELETE',
    url: userUrl(userId)
  })
